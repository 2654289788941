<template>
  <v-autocomplete
    ref="prospectAutoSuggest"
    v-model="inputData"
    placeholder="Cari prospek..."
    :label="label"
    outlined
    dense
    deletable-chips
    chips
    return-object
    :multiple="multiple"
    :loading="loadingProspect"
    :items="options"
    item-value="id"
    item-text="name"
    small-chips
    hide-details="auto"
    no-filter
    :rules="inputRules"
    :disabled="disabled"
    @update:search-input="onSearch"
    @update:list-index="onIndex"
  >
    <template #no-data>
      <div
        class="px-4 py-2 text-subtitle-2 text--disabled text-center"
      >
        <p>
          Prospek tidak ditemukan
        </p>
      </div>
    </template>
    <template
      #item="{ item }"
    >
      <v-card
        outlined
        class="pa-2 w-full rounded my-1"
      >
        <div class="d-flex flex-column align-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="text-subtitle-2 mb-1"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.name }}
              </span>
            </template>
            <span>{{ item.description ? `Deskripsi : ${item.description}` : 'Tidak ada deskripsi' }}</span>
          </v-tooltip>
          <ChipDisplay
            :status="item.status"
          />
        </div>
      </v-card>
    </template>
    <template
      v-if="filterCustomer"
      #append-item
    >
      <div
        class="px-4 mb-n3 pt-2 text-caption text--disabled"
        style="max-width: 300px"
      >
        <p>
          Menampilkan prospek dari pelanggan {{ filterCustomer.name }}
        </p>
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import {
  ref, computed, onMounted, watch,
} from '@vue/composition-api'
import { minArrayLength, maxArrayLength } from '@core/utils/validation'
import useProspect from '@/composables/useProspect'
import ChipDisplay from '@/views/prospect/component/ChipDisplay.vue'

export default {
  components: {
    ChipDisplay,
  },
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'Prospek',
    },
    filterCustomer: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const prospectAutoSuggest = ref()
    const isEmpty = ref(false)
    const searchQuery = ref('')

    const {
      loadingProspect, prospectList,
      debouncedFetchProspects, fetchProspect,
    } = useProspect()

    const inputData = computed({
      get() {
        return props.value
      },
      set(val) {
        emit('input', val)
      },
    })

    const filterCustomerProps = computed(() => props.filterCustomer)

    watch(filterCustomerProps, () => {
      emit('input', null)

      fetchProspect({
        customer: props.filterCustomer ? [props.filterCustomer.id] : null,
        search: searchQuery.value ? searchQuery.value : null,
      })
    })

    const inputRules = computed(() => {
      if (props.multiple && (props.min || props.max)) {
        const multipleRules = []

        if (props.max) multipleRules.push(maxArrayLength(inputData.value, props.max))
        if (props.min) multipleRules.push(minArrayLength(inputData.value, props.min))

        return [...props.rules, ...multipleRules]
      }

      return props.rules
    })

    const options = computed(() => {
      if (inputData.value) {
        return Array.isArray(inputData.value) ? [
          ...prospectList.value,
          ...inputData.value,
        ] : [
          ...prospectList.value,
          inputData.value,
        ]
      }

      return prospectList.value
    })

    const onSearch = data => {
      searchQuery.value = data
      debouncedFetchProspects({
        customer: props.filterCustomer ? [props.filterCustomer.id] : null,
        search: searchQuery.value ? searchQuery.value : null,
      })
    }

    const onIndex = () => {
      isEmpty.value = prospectAutoSuggest.value.filteredItems.length === 0
    }

    onMounted(() => {
      if (props.disabled) {
        fetchProspect({
          customer: props.filterCustomer ? [props.filterCustomer.id] : null,
          search: searchQuery.value ? searchQuery.value : null,
        })
      }
    })

    return {
      prospectAutoSuggest,
      isEmpty,

      loadingProspect,

      inputData,
      inputRules,
      options,

      onIndex,
      onSearch,
    }
  },
}
</script>

<style>

</style>
