<template>
  <div>
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="6"
      >
        <comment-box
          ref="commentBox"
        />
      </v-col>
    </v-row>

    <v-card
      class="mb-2"
    >
      <v-card-title>
        Test Komponent
      </v-card-title>
      <v-card-text>
        <customer-auto-suggest
          v-model="customer"
          class="mb-2"
        />
        <job-auto-suggest
          v-model="job"
          class="mb-2"
          :filter-customer="customer"
          :disabled="!customer"
        />
        <prospect-auto-suggest
          v-model="prospect"
          class="mb-2"
          :filter-customer="customer"
          :disabled="!customer"
        />
        {{ prospect }}
        <tag-customer v-model="input" />
        <city-picker v-model="city" />
        {{ city }}
        <drop-file
          max-file="3"
          max-size="5000"
        />
        <color-picker
          v-model="colorInput"
          label="Label Warna"
          class="mt-8 mb-2"
        />
        <span class="d-block">{{ colorInput }}</span>
        <v-btn
          class="mt-2"
          @click="summonNotification"
        >
          show notification
        </v-btn>
        <split-button
          class="mt-4"
          label="Show Document"
          @click="$refs.commentBox.close()"
        >
          <template #list>
            <v-list-item @click="summonNotification()">
              Menu 1
            </v-list-item>
            <v-list-item>
              Menu 2
            </v-list-item>
            <v-list-item @click="$refs.commentBox.findScroll(232)">
              scroll
            </v-list-item>
          </template>
        </split-button>
      </v-card-text>
    </v-card>

    <document-viewer ref="docViewer" />
  </div>
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import TagCustomer from '@/components/inputs/TagCustomer.vue'
import DropFile from '@/components/inputs/DropFile.vue'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import SplitButton from '@/components/inputs/SplitButton.vue'
import CommentBox from '@/components/misc/CommentBox.vue'
import DocumentViewer from '@/components/misc/DocumentViewer.vue'
import dateFormat from '@/utils/dateFormat'
import CityPicker from '@/components/inputs/CityPicker.vue'
import CustomerAutoSuggest from '@/components/inputs/CustomerAutoSuggest.vue'
// import jobAutoSuggest from '@/components/inputs/JobAutoSuggest.vue'
import jobAutoSuggest from '@/components/inputs/JobAutoSuggest.vue'
import ProspectAutoSuggest from '@/components/inputs/ProspectAutoSuggest.vue'

export default {
  components: {
    TagCustomer,
    DropFile,
    ColorPicker,
    SplitButton,
    CommentBox,
    DocumentViewer,
    CityPicker,
    CustomerAutoSuggest,
    jobAutoSuggest,
    ProspectAutoSuggest,
  },
  setup() {
    const input = ref([])
    const fileInput = ref([])
    const colorInput = ref('#6285f6')
    const city = ref({
      id: 87,
      name: 'Surabaya',
    })
    const visibility = ref()

    const summonNotification = () => {
      Vue.notify({
        title: 'Oke Sip!',
        text: 'Lorem Ipsum Teks Panjang Sekali',
      })
    }

    const visibilityPicker = ref(null)
    const getUsers = () => {
      console.log(visibilityPicker.value.getUsersSelection())
    }

    const customer = ref({
      id: 46,
      name: 'Adonis Rahardian',
      phone_number: null,
      email: null,
    })

    const job = ref()

    const prospect = ref()

    return {
      input,
      fileInput,
      colorInput,
      summonNotification,
      dateFormat,
      city,
      visibility,
      visibilityPicker,
      getUsers,
      customer,
      job,
      prospect,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>

<style>

</style>
