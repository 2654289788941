import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{ref:"prospectAutoSuggest",attrs:{"placeholder":"Cari prospek...","label":_vm.label,"outlined":"","dense":"","deletable-chips":"","chips":"","return-object":"","multiple":_vm.multiple,"loading":_vm.loadingProspect,"items":_vm.options,"item-value":"id","item-text":"name","small-chips":"","hide-details":"auto","no-filter":"","rules":_vm.inputRules,"disabled":_vm.disabled},on:{"update:search-input":_vm.onSearch,"update:list-index":_vm.onIndex},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"px-4 py-2 text-subtitle-2 text--disabled text-center"},[_c('p',[_vm._v(" Prospek tidak ditemukan ")])])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c(VCard,{staticClass:"pa-2 w-full rounded my-1",attrs:{"outlined":""}},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text-subtitle-2 mb-1"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description ? ("Deskripsi : " + (item.description)) : 'Tidak ada deskripsi'))])]),_c('ChipDisplay',{attrs:{"status":item.status}})],1)])]}},(_vm.filterCustomer)?{key:"append-item",fn:function(){return [_c('div',{staticClass:"px-4 mb-n3 pt-2 text-caption text--disabled",staticStyle:{"max-width":"300px"}},[_c('p',[_vm._v(" Menampilkan prospek dari pelanggan "+_vm._s(_vm.filterCustomer.name)+" ")])])]},proxy:true}:null],null,true),model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}})}
var staticRenderFns = []

export { render, staticRenderFns }